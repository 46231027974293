import React, {useState, useCallback, useRef, useEffect} from 'react';
import {useParams} from 'react-router';
import projectFlows from '../../configs/projectFlows.json';
import publishingFLows from '../../configs/publishingFlows.json';
import navi from '../../component/navigation/Component-navigation';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as turf from '@turf/turf';
import {MapboxStyleDefinition,  MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import mapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import "mapbox-gl-style-switcher/styles.css";
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import amplify from 'aws-amplify';
import { useSwiper } from 'swiper/react';
import mapboxSdkGeoCoding from  '@mapbox/mapbox-sdk/services/geocoding';
import mapboxgl from 'mapbox-gl';
import { AddressAutofill } from '@mapbox/search-js-react';

export default function Page() {

	let {id, flowtype, json} = useParams();
	const ref = useRef(null);
	const navigateBack = useNavigate();
	const [project,setProject] = useState(null);
	const [parcelLoaded,setParcelLoaded] = useState(false);
	const [showButton,setShowButton] = useState(false);
	const [feature, setFeature] = useState();
	const [map, setMap] = useState(null);
	const [addressStreet, setAddressStreet] = useState(null);
	const [addressZipCode, setAddressZipCode] = useState(null);
	const [addressRegionCode, setAddressRegionCode] = useState(null);
	const [addressTown, setAddressTown] = useState(null);
	const [lat, setLat] = useState(null);
	const [lon, setLon] = useState(null);
	const [errorAddressStreet, setErrorAddressStreet] = useState(false);
	const swiper = useSwiper();
	var flows = projectFlows;


	if(typeof flowtype !== "undefined" && flowtype == "publishing"){
		var flows = publishingFLows;
	}

	// map token
    const mapKey = process.env.REACT_APP_MAPS_KEY;
 	mapboxgl.accessToken = mapKey;

	// map styles
    const styles: MapboxStyleDefinition[] = [
            {
                title: "Satellit",
                uri:"mapbox://styles/mapbox/satellite-v9"
            },
            {
                title: "Straße",
                uri:"mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji"
            }
        ];

	const updateProjekt = (data,source) => {

		var newProject = {...project,...data}

		for (var z in projectFlows?.flows) {
			for (var y in projectFlows?.flows[z]["slides"]) {
				for (var x in projectFlows?.flows[z]["slides"][y]["fields"]) {
					var d = projectFlows?.flows[z]["slides"][y]["fields"][x];
					if(d.type === "calculation") {
						if(d.action === "multiFrom") {
							newProject[d.id] = calculateMultiplyFrom(d,newProject);
						} else if(d.action === "divideFrom") {
							newProject[d.id] = calculateDivideFrom(d,newProject);
						} else if(d.action === "addFrom") {
							newProject[d.id] = calculateAddFrom(d,newProject);
						} else if(d.action === "addPercentFrom") {
							newProject[d.id] = calculateAddPercentFrom(d,newProject);
						} else if(d.action === "percentFrom") {
							newProject[d.id] = calculatePercentFrom(d,newProject);
						} else if(d.action === "minusFrom") {
							newProject[d.id] = calculateMinusFrom(d,newProject);
						}
					}
				}
			}
		}




		setProject(newProject);
		return newProject;
	};

	// store project
    const saveProject = (type) => {

		var productNew = {...project};


		// set header
		var headers = new Headers();
            headers.append("Content-Type", "application/json");

		// Build json body
        var raw = {...productNew};

		raw.status = productNew.status;
		raw.lastUpdateDate = Date.now();
		raw.json = productNew;
		delete(raw.json.id);
		delete(raw.json.userId);
		delete(raw.json.companyId);
		delete(raw.json.lastUpdateDate);
		delete(raw.json.json);
		delete(raw.json.creationDate);


		// build request
        var requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(raw),
          redirect: 'follow'
        };

		// send request
        const response = fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions)
			.catch(error => console.log('error', error));

		// redirect if end of swip
		if(type === "submit")
		{
			navigateBack('/project/'+id );
		}

		// init map
		handelMapLoad(id, productNew, false);

    }

    // load map
    const handelMapLoad = (e, p, forceUpdate) => {



    	if ((typeof ref !== "undefined" && typeof map !== "undefined" && ref?.current && !map && e && p) || forceUpdate === true) {
    			 let mapPlots = [];
    			 let mapMaster = [];

    			 if(typeof  p?.mapPolygon?.features?.[0]?.properties?.center !== "undefined") {
                     var mapCenter = p?.mapPolygon?.features?.[0]?.properties?.center;
                     var mapZoom = 16;
                 } else if(typeof p?.mapCenter !== "undefined") {
                     var mapCenter = p.mapCenter;
                     var mapZoom = 16;
                 } else {
                     var mapCenter = [10, 51.00];
                     var mapZoom = 6;
                 }

    		     const map = new mapboxgl.Map({
    		         displayControlsDefault: false,
    		         container: ref?.current,
    		         style: "mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji",
    				 center: mapCenter,
    				 zoom: mapZoom,
    		     });

				 var draw = new mapboxDraw({
					  displayControlsDefault: false,
					  controls: {
						  polygon: true,
						  trash: true
					  },
					  styles: [
						  {
							   'id': 'gl-draw-polygon-fill-inactive',
							   'type': 'fill',
							   'filter': ['all', ['==', 'active', 'false'],
								   ['==', '$type', 'Polygon'],
								   ['!=', 'mode', 'static']
							   ],
							   'paint': {
								   'fill-color': '#3bb2d0',
								   'fill-outline-color': '#3bb2d0',
								   'fill-opacity': 0.1
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-fill-active',
							   'type': 'fill',
							   'filter': ['all', ['==', 'active', 'true'],
								   ['==', '$type', 'Polygon']
							   ],
							   'paint': {
								   'fill-color': '#fbb03b',
								   'fill-outline-color': '#fbb03b',
								   'fill-opacity': 0.1
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-midpoint',
							   'type': 'circle',
							   'filter': ['all', ['==', '$type', 'Point'],
								   ['==', 'meta', 'midpoint']
							   ],
							   'paint': {
								   'circle-radius': 3,
								   'circle-color': '#fbb03b'
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-stroke-inactive',
							   'type': 'line',
							   'filter': ['all', ['==', 'active', 'false'],
								   ['==', '$type', 'Polygon'],
								   ['!=', 'mode', 'static']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#3bb2d0',
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-stroke-active',
							   'type': 'line',
							   'filter': ['all', ['==', 'active', 'true'],
								   ['==', '$type', 'Polygon']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#fbb03b',
								   'line-dasharray': [0.2, 2],
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-line-inactive',
							   'type': 'line',
							   'filter': ['all', ['==', 'active', 'false'],
								   ['==', '$type', 'LineString'],
								   ['!=', 'mode', 'static']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#c4c4c4',
								   'line-width': 16
							   }
						   },
						   {
							   'id': 'gl-draw-line-active',
							   'type': 'line',
							   'filter': ['all', ['==', '$type', 'LineString'],
								   ['==', 'active', 'true']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#fbb03b',
								   'line-dasharray': [0.2, 2],
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
							   'type': 'circle',
							   'filter': ['all', ['==', 'meta', 'vertex'],
								   ['==', '$type', 'Point'],
								   ['!=', 'mode', 'static']
							   ],
							   'paint': {
								   'circle-radius': 5,
								   'circle-color': '#fff'
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-and-line-vertex-inactive',
							   'type': 'circle',
							   'filter': ['all', ['==', 'meta', 'vertex'],
								   ['==', '$type', 'Point'],
								   ['!=', 'mode', 'static']
							   ],
							   'paint': {
								   'circle-radius': 3,
								   'circle-color': '#fbb03b'
							   }
						   },
						   {
							   'id': 'gl-draw-point-point-stroke-inactive',
							   'type': 'circle',
							   'filter': ['all', ['==', 'active', 'false'],
								   ['==', '$type', 'Point'],
								   ['==', 'meta', 'feature'],
								   ['!=', 'mode', 'static']
							   ],
							   'paint': {
								   'circle-radius': 5,
								   'circle-opacity': 1,
								   'circle-color': '#fff'
							   }
						   },
						   {
							   'id': 'gl-draw-point-inactive',
							   'type': 'circle',
							   'filter': ['all', ['==', 'active', 'false'],
								   ['==', '$type', 'Point'],
								   ['==', 'meta', 'feature'],
								   ['!=', 'mode', 'static']
							   ],
							   'paint': {
								   'circle-radius': 3,
								   'circle-color': '#3bb2d0'
							   }
						   },
						   {
							   'id': 'gl-draw-point-stroke-active',
							   'type': 'circle',
							   'filter': ['all', ['==', '$type', 'Point'],
								   ['==', 'active', 'true'],
								   ['!=', 'meta', 'midpoint']
							   ],
							   'paint': {
								   'circle-radius': 7,
								   'circle-color': '#fff'
							   }
						   },
						   {
							   'id': 'gl-draw-point-active',
							   'type': 'circle',
							   'filter': ['all', ['==', '$type', 'Point'],
								   ['!=', 'meta', 'midpoint'],
								   ['==', 'active', 'true']
							   ],
							   'paint': {
								   'circle-radius': 5,
								   'circle-color': '#fbb03b'
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-fill-static',
							   'type': 'fill',
							   'filter': ['all', ['==', 'mode', 'static'],
								   ['==', '$type', 'Polygon']
							   ],
							   'paint': {
								   'fill-color': '#404040',
								   'fill-outline-color': '#404040',
								   'fill-opacity': 0.1
							   }
						   },
						   {
							   'id': 'gl-draw-polygon-stroke-static',
							   'type': 'line',
							   'filter': ['all', ['==', 'mode', 'static'],
								   ['==', '$type', 'Polygon']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#404040',
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-line-static',
							   'type': 'line',
							   'filter': ['all', ['==', 'mode', 'static'],
								   ['==', '$type', 'LineString']
							   ],
							   'layout': {
								   'line-cap': 'round',
								   'line-join': 'round'
							   },
							   'paint': {
								   'line-color': '#404040',
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-point-static',
							   'type': 'circle',
							   'filter': ['all', ['==', 'mode', 'static'],
								   ['==', '$type', 'Point']
							   ],
							   'paint': {
								   'circle-radius': 5,
								   'circle-color': '#404040'
							   }
						   },

						   {
							   'id': 'gl-draw-polygon-color-picker',
							   'type': 'fill',
							   'filter': ['all', ['==', '$type', 'Polygon'],
								   ['has', 'user_portColor']
							   ],
							   'paint': {
								   'fill-color': ['get', 'user_portColor'],
								   'fill-outline-color': ['get', 'user_portColor'],
								   'fill-opacity': 0.5
							   }
						   },
						   {
							   'id': 'gl-draw-line-color-picker',
							   'type': 'line',
							   'filter': ['all', ['==', '$type', 'LineString'],
								   ['has', 'user_portColor']
							   ],
							   'paint': {
								   'line-color': ['get', 'user_portColor'],
								   'line-width': 2
							   }
						   },
						   {
							   'id': 'gl-draw-point-color-picker',
							   'type': 'circle',
							   'filter': ['all', ['==', '$type', 'Point'],
								   ['has', 'user_portColor']
							   ],
							   'paint': {
								   'circle-radius': 3,
								   'circle-color': ['get', 'user_portColor']
							   }
						   },

					   ]

				  });
				 map.addControl(draw);

				 map.on('draw.create', function (e) {
					var a        = draw.getAll();
					var center   = turf.center(a);
					var productNew = {};

						productNew["mapPolygon"] = a;
						productNew["mapPolygon"].features[0].properties ={center:center?.geometry.coordinates};

						const answer = document.getElementById('calculated-area');
						if (a.features.length > 0) {
							const area = turf.area(a);
							const rounded_area = Math.round(area * 100) / 100;
							answer.innerHTML = `<p>Das eingezeichnete Grundstück verfüg über eine FLäche von <strong>${rounded_area}</strong> m²</p>`;
							productNew["areaTotal"] = rounded_area;
						}


						console.log("----- create by Map -----");

					 getProjectData(productNew);

				});

				 map.on('draw.update', function (e) {
					var a        = draw.getAll();
					var center   = turf.center(a);
					var productNew = {};



						productNew["mapPolygon"] = a;
						productNew["mapPolygon"].features[0].properties ={center:center?.geometry.coordinates};

						const answer = document.getElementById('calculated-area');
						if (a.features.length > 0) {
							const area = turf.area(a);
							const rounded_area = Math.round(area * 100) / 100;
							answer.innerHTML = `<p>Das eingezeichnete Grundstück verfüg über eine FLäche von <strong>${rounded_area}</strong> m²</p>`;
							productNew["areaTotal"] = rounded_area;
						}

						console.log("----- Update by Map -----");

					 getProjectData(productNew);
				});

				 map.on('draw.delete', function (e) {
					var a        	 = draw.getAll();
					var productNew = {};
						productNew["mapPolygon"] = [];

						const answer = document.getElementById('calculated-area');
						if (a.features.length > 0) {
							const area = turf.area(a);
							const rounded_area = Math.round(area * 100) / 100;
							answer.innerHTML = `<p>Das eingezeichnete Grundstück verfüg über eine FLäche von <strong>${rounded_area}</strong> m²</p>`;
							productNew["areaTotal"] = rounded_area;
						}

						console.log("----- delete by Map -----");

						getProjectData(productNew);
				});


				 map.addControl(new mapboxgl.NavigationControl());

				map.addControl(new MapboxStyleSwitcherControl(styles));

    			mapPlots = p?.mapPolygon;

				var a = 0;
                map.on('load', function () {

					 let lastZoom = map.getZoom();

                     map.on('zoom', () => {
                        const currentZoom = map.getZoom();
                        if (currentZoom > lastZoom) {

                            if(map.getZoom() > 14 && a === 0) {

								if(p?.addressRegionCode === "NW") {
									map.addSource('wms-NW-source', {
										'type': 'raster',
										'tiles': [
											'https://www.wms.nrw.de/geobasis/wms_nw_inspire-flurstuecke_alkis?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-NW-layer',
											'type': 'raster',
											'source': 'wms-NW-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "TH") {
									map.addSource('wms-TH-source', {
										'type': 'raster',
										'tiles': [
											'https://www.geoproxy.geoportal-th.de/geoproxy/services/INSPIREcp?language=ger&REQUEST=GetMap&VERSION=1.1.1&SERVICE=WMS&STYLES=default&LAYERS=CP.CadastralParcel&srs=EPSG%3A3857&WIDTH=526&HEIGHT=526&FORMAT=image%2Fpng&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 200
									});
									map.addLayer(
										{
											'id': 'wms-TH-layer',
											'type': 'raster',
											'source': 'wms-TH-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "HE") {
									map.addSource('wms-HE-source', {
										'type': 'raster',
										'tiles': [
											'https://www.gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-maps.ows?language=ger&REQUEST=GetMap&VERSION=1.1.1&SERVICE=WMS&LAYERS=he_alk&srs=EPSG%3A3857&WIDTH=526&HEIGHT=526&FORMAT=image%2Fpng&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 200
									});

									map.addLayer(
										{
											'id': 'wms-HE-layer',
											'type': 'raster',
											'source': 'wms-HE-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "RP") {
									map.addSource('wms-RH-source', {
										'type': 'raster',
										'tiles': [
											'https://geo5.service24.rlp.de/wms/liegenschaften_rp.fcgi?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=WMS_RP_ALKIS_Liegenschaften&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-RH-layer',
											'type': 'raster',
											'source': 'wms-RH-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "MV") {
									map.addSource('wms-MV-source', {
										'type': 'raster',
										'tiles': [
											'https://www.geodaten-mv.de/dienste/inspire_cp_alkis_view?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-MV-layer',
											'type': 'raster',
											'source': 'wms-MV-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "BB") {
									map.addSource('wms-BB-source', {
										'type': 'raster',
										'tiles': [
											'https://inspire.brandenburg.de/services/cp_alkis_wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-BB-layer',
											'type': 'raster',
											'source': 'wms-BB-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "ST") {
									map.addSource('wms-ST-source', {
										'type': 'raster',
										'tiles': [
											'https://www.geodatenportal.sachsen-anhalt.de/wss/service/ST_LVermGeo_ALKIS_WMS_AdV_konform_App/guest?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=adv_alkis_flurstuecke,Farbe&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-ST-layer',
											'type': 'raster',
											'source': 'wms-ST-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "SN") {
									map.addSource('wms-ST-source', {
										'type': 'raster',
										'tiles': [
											'https://geodienste.sachsen.de/iwms_geosn_flurstuecke/guest?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-ST-layer',
											'type': 'raster',
											'source': 'wms-ST-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

								if(p?.addressRegionCode === "NI") {
									map.addSource('wms-NI-source', {
										'type': 'raster',
										'tiles': [
											'https://opendata.lgln.niedersachsen.de/doorman/noauth/alkis_wms?VERSION=1.3.0&REQUEST=GetMap&SERVICE=WMS&FORMAT=image%2Fpng&LAYERS=ALKIS&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
										],
										'tileSize': 1126
									});
									map.addLayer(
										{
											'id': 'wms-NI-layer',
											'type': 'raster',
											'source': 'wms-NI-source',
											'paint': {}
										},
										'building' // Place layer under labels, roads and buildings.
									);
								}

                                a = 1;
                            }

                        }

                      lastZoom = currentZoom;
                    });


                });

                map.on('style.load', function () {
                     if( typeof mapPlots !== "undefined" && mapPlots !== null ) {
                         draw.add(mapPlots);
                     }

                     // Add a data source containing GeoJSON data.
    				 map.addSource('mapLayer1', {
    				    'type': 'geojson',
    				    'data': { type:"FeatureCollection",features:[ mapMaster ] }
    				 });

    				 // Add a new layer to visualize the polygon.
    				 map.addLayer({
    				     'id': 'mapLayer1',
    				     'type': 'fill',
    				     'source': 'mapLayer1',
    				     'layout': {},
    				     'paint': {
    				     'fill-color': '#132541',
    				     'fill-opacity': 0.5
    				     }
    				 });
                });

    		    setMap(map);
    		}
    		return true;
        }


	const handleRetrieve = (res) => {
	    const feature = res.features[0];
	    const projectNew = {...project};
		if(feature?.geometry?.coordinates?.[0] !== "undefined") {
			projectNew["addressStreet"] = feature?.properties?.address_line1;

			projectNew["addressTown"] = feature?.properties?.address_level2;
			setAddressTown(feature?.properties?.address_level2);

			projectNew["addressDistrict"] = feature?.properties?.address_level3;

			projectNew["addressZipCode"] = feature?.properties?.postcode;
			setAddressZipCode(feature?.properties?.postcode);

			projectNew["addressRegionCode"] = feature?.properties?.region_code;
			setAddressRegionCode(feature?.properties?.region_code);

			projectNew["mapCenter"] = [feature?.geometry?.coordinates?.[0], feature?.geometry?.coordinates?.[1]];
			setLat(feature?.geometry?.coordinates?.[0]);
			setLon(feature?.geometry?.coordinates?.[1]);


			var newData = updateProjekt(projectNew,"update projekt add ->  Address");

			handelMapLoad(id, newData, true);
			setFeature(feature);

		}
	};

	// checkbox change
    const handleCheckChange = (e) => {
		var data  = {}
			data[e.target.name] = e.target.checked;
			updateProjekt(data,"update projekt add ->  Checkbox");
    }

	// select change
    const handleChangeSelect = (e) => {
		var data  = {}
			data[e.target.name] = e.target.value;
			updateProjekt(data,"update projekt add ->  select");
    }

	// text change
    const handleChange = (e) => {
		var data  = {}
		data[e.target.name] = e.target.value;
		updateProjekt(data,"update projekt add ->  text");
    }

	// number change
    const handleChangeNumber = (e) => {
		var data  = {}
		data[e.target.name] = parseFloat(e.target.value);
		updateProjekt(data,"update projekt add ->  Number");
    }

    // calculates
    const calculateDivideFrom = (calc,projectCalcs) => {
        if(projectCalcs?.[calc?.from]) {
	 		let from = projectCalcs?.[calc?.from];
			if( calc.fields.length > 0) {

	                 if( projectCalcs?.[calc.fields[0]]) {
	                    from = (parseFloat(from) / parseFloat(projectCalcs?.[calc.fields[0]])).toFixed(2);
	                 }

				projectCalcs[calc.id] = parseFloat(from).toFixed(2);
			}
			else
			{
				projectCalcs[calc.id] = parseFloat(0).toFixed(2);
			}

        }
        else
        {
			projectCalcs[calc.id] = parseFloat(0).toFixed(2);
        }

		return projectCalcs[calc.id];

    }

    const calculateMinusFrom = (calc,projectCalcs) => {
        if(projectCalcs?.[calc?.from]) {
	 		let from = projectCalcs?.[calc?.from];
			if( calc.fields.length > 0) {
		        for (let i = 0; i < calc.fields.length; i++) {
	                 if( projectCalcs?.[calc.fields[i]]) {
	                    from = (parseFloat(from) - parseFloat(projectCalcs?.[calc.fields[i]])).toFixed(2);
	                 }
	             }
				projectCalcs[calc.id] = parseFloat(from).toFixed(2);
			}
			else
			{
				projectCalcs[calc.id] = parseFloat(0).toFixed(2);
			}

        }
        else
        {
			projectCalcs[calc.id] = parseFloat(0).toFixed(2);
        }

		return projectCalcs[calc.id];

    }

    const calculateAddFrom = (calc,projectCalcs) => {
        if(projectCalcs?.[calc?.from]) {
	  		let from = projectCalcs?.[calc?.from];
	  		if( calc.fields.length > 0 ) {
		        for (let i = 0; i < calc.fields.length; i++) {

	                 if( projectCalcs?.[calc.fields[i]] && projectCalcs?.[calc.fields[i]] > 0 ) {
	                    from = (parseFloat(from) + parseFloat(projectCalcs?.[calc.fields[i]])).toFixed(2);
	                 }
	                 else
	                 {
	                    from = parseFloat(from).toFixed(2);
	                 }

	             }
				projectCalcs[calc.id] = parseFloat(from).toFixed(2);
             }
			else
			{
				projectCalcs[calc.id] =parseFloat(0).toFixed(2);
			}
        }
        else
        {
			projectCalcs[calc.id] = parseFloat(0).toFixed(2);
        }

        return projectCalcs[calc.id];
    }

    const calculateAddPercentFrom = (calc,projectCalcs) => {
        if(projectCalcs?.[calc?.from]) {
	  		let from = parseFloat(projectCalcs?.[calc?.from]);
	  		if( calc.fields.length > 0 ) {
		        for (let i = 0; i < calc.fields.length; i++) {

	                 if( projectCalcs?.[calc.fields[i]] && projectCalcs?.[calc.fields[i]] > 0 ) {
	                    from = (parseFloat(from) + ( parseFloat(parseFloat(projectCalcs?.[calc?.from]) * ( parseFloat(projectCalcs?.[calc.fields[i]]) / 100)))).toFixed(2);
	                 }
	                 else
	                 {
	                    from = parseFloat(from).toFixed(2);
	                 }

	             }
				projectCalcs[calc.id] = parseFloat(from).toFixed(2);
             }
			else
			{
				projectCalcs[calc.id] =parseFloat(0).toFixed(2);
			}
        }
        else
        {
			projectCalcs[calc.id] = parseFloat(0).toFixed(2);
        }

        return projectCalcs[calc.id];
    }

    const calculatePercentFrom = (calc,projectCalcs) => {
        if(project?.[calc?.from] ) {
			let from = parseFloat(projectCalcs?.[calc?.from]);
	  		if(typeof calc.fields[0] !== "undefined" ) {


                 if( projectCalcs?.[calc.fields[0]] && projectCalcs?.[calc.fields[0]] > 0 ) {
                    from = parseFloat(from) * (parseFloat(projectCalcs?.[calc.fields[0]]) / 100);
                 }
                 else
                 {
                    from = parseFloat(0);
                 }


				projectCalcs[calc.id] =  parseFloat(from).toFixed(2);
             }
			else
			{
				projectCalcs[calc.id] =  parseFloat(0);
			}
        }
        else
        {
			projectCalcs[calc.id] =   parseFloat(0);
        }

        return projectCalcs[calc.id];
    }

    const calculateMultiplyFrom = (calc,projectCalcs) => {
        if(projectCalcs?.[calc?.from] ) {
			let from = parseFloat(projectCalcs?.[calc?.from]);
	  		if(typeof calc.fields[0] !== "undefined" ) {


                 if( projectCalcs?.[calc.fields[0]] && projectCalcs?.[calc.fields[0]] > 0 ) {
                    from = parseFloat(from) * (parseFloat(projectCalcs?.[calc.fields[0]]));
                 }
                 else
                 {
                    from = parseFloat(0);
                 }


				projectCalcs[calc.id] =  parseFloat(from).toFixed(2);
             }
			else
			{
				projectCalcs[calc.id] =  parseFloat(0);
			}
        }
        else
        {
			projectCalcs[calc.id] =   parseFloat(0);
        }

        return projectCalcs[calc.id];
    }

	// requiert
    const handleRequirements = (schema,name) => {

        if(schema.required  && schema.required.includes(name))
        {
            return true;
        }
		return false;
    }

	// get project
	async function getProject() {

        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

		if(typeof flowtype !== "undefined" && flowtype == "publishing")
        {
			 var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projectsDrafts/"+id, requestOptions)
                      .then(response => { return response.json() })
                      .catch(error => console.log('error', error));
        }
        else
        {
			 var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions)
                      .then(response => { return response.json() })
                      .catch(error => console.log('error', error));
		}

		var result =  {...data[0], ...data[0].json};
		delete(result.json);
		setProject(result);
    }

	async function getProjectData(p) {

		var requestOptions = {
			method: 'GET',
			redirect: 'follow'
		};

		if(typeof flowtype !== "undefined" && flowtype == "publishing")
		{
			var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projectsDrafts/"+id, requestOptions)
				.then(response => { return response.json() })
				.catch(error => console.log('error', error));
		}
		else
		{
			var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions)
				.then(response => { return response.json() })
				.catch(error => console.log('error', error));
		}


		var result =  {...data[0], ...data[0].json};
		    result.areaTotal =  p?.areaTotal;
		    result.mapPolygon =  p?.mapPolygon;
		delete(result.json);
		updateProjekt(result,"get projectData ");

    }




	// init
    useEffect(() => {

		if(project?.potentialId) {
			for (var i = 0; i <  projectFlows?.flows.projectAddress.slides.length; i++){
				if(projectFlows?.flows.projectAddress.slides[i]?.name === 'plotBorders' || projectFlows?.flows.projectAddress.slides[i]?.name === 'locationOfProject') {
					delete projectFlows?.flows.projectAddress.slides[i];
				}
			}
		}

		if(project === null) {
			getProject();
		}


		if(project !== null)
		{
	        setAddressStreet(project?.addressStreet);
			setAddressTown(project?.addressTown);
	        setAddressZipCode(project?.addressZipCode);
	        setAddressRegionCode(project?.addressRegionCode);
			setLat(project?.mapCenter?.[0]);
			setLon(project?.mapCenter?.[1]);
		}

    }, [project]);

	return(
            <div>
                { navi(flows?.flows[json]?.pageTitle, "/project/"+id) }

                 <div id={"page-project-editor"} className={"wrapper-content"}>

                     <Swiper
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          spaceBetween={150}
                          slidesPerView={1}
                          allowTouchMove={false}
                          autoHeight={false}
                          navigation
                          pagination={{ clickable: true }}
                          onSlideChange={() => {
                                saveProject(project,null);
                            }
                          }
                          onReachEnd={() => {
	                            setShowButton(true);
	                      }}
                        >
                           {
                              flows?.flows[json]?.slides?.map(function(c){
                                 return (
                                    <SwiperSlide>

                                        <div className={"editorContent"}>
                                            <div>
                                                <img alt={c?.title} src={require("../../assets/image/"+c?.image)} />
                                                <h3>{c?.title}</h3>
                                                <p>
                                                   {c?.message}
                                                </p>
                                                <div className={"editorContentFields"}>
                                                    { c?.fields?.map(function(d) {
                                                       if(d?.type === "text") {
                                                           if(d?.ShowIfFieldGiven) {
                                                               if(typeof project?.[d?.ShowIfFieldGiven] !== "undefined" && project?.[d?.ShowIfFieldGiven] !== false)  {
                                                                   return (
				                                                      <TextField
				                                                       name={d?.id}
				                                                       required={handleRequirements(flows?.flows[json],d.id)}
				                                                       onChange={handleChange}
				                                                       style={{width: d?.width, marginLeft: d?.margin}}
				                                                       value={(project?.[d?.id]) ? project?.[d?.id] : "" }
				                                                       InputProps={{
				                                                         startAdornment: (
				                                                           <InputAdornment position="start">
				                                                               {d?.iconBefore}
				                                                           </InputAdornment>
				                                                         ),
				                                                       endAdornment: (
				                                                           <InputAdornment position="end">
				                                                              {d?.iconAfter}
				                                                           </InputAdornment>
				                                                         ),
				                                                       }}

				                                                       {...d}
				                                                       />
				                                                   );
                                                               }
                                                           }
                                                           else
                                                           {
                                                               return (
                                                                  <TextField
                                                                   name={d?.id}
                                                                   required={handleRequirements(flows?.flows[json],d.id)}
                                                                   onChange={handleChange}
                                                                   style={{width: d?.width, marginLeft: d?.margin}}
                                                                   value={(project?.[d?.id]) ? project?.[d?.id] : "" }
                                                                   InputProps={{
                                                                     startAdornment: (
                                                                       <InputAdornment position="start">
                                                                           {d?.iconBefore}
                                                                       </InputAdornment>
                                                                     ),
                                                                   endAdornment: (
                                                                       <InputAdornment position="end">
                                                                          {d?.iconAfter}
                                                                       </InputAdornment>
                                                                     ),
                                                                   }}

                                                                   {...d}
                                                                   />
                                                               );
                                                           }
                                                       }

                                                       if(d?.type === "addressStreet") {
                                                               return (
                                                                  <form className={(typeof lon === "undefined" && typeof addressZipCode === "undefined")? "addressBlock error": "addressBlock"}>
                                                                    <AddressAutofill onRetrieve={(res) => { handleRetrieve(res)}} options = {{ language: 'de',    types: ['poi'], country: 'DE'}}  accessToken={mapKey}>
	                                                                    <TextField
																		   label={"Adresse und Hausnummer"}
                                                                           name={"address"}
                                                                           InputLabelProps={{
                                                                               shrink: true,
                                                                           }}

                                                                           placeholder={"Bitte Straße und Hausnummer des Ziels eintragen."}
																		   onChange={(e) => {
																			   setAddressStreet(e.target.value)

																			   if(typeof e.target.value !== "" && typeof project["mapCenter"] === "undefined") {
																				   setErrorAddressStreet(true);
																			   } else {
																				   setErrorAddressStreet(false);
																			   }
																			   }}
                                                                           value={addressStreet}
																		   autoComplete="address-line1"
                                                                           type={"text"}
																		   helperText={(typeof lon === "undefined" && typeof addressZipCode === "undefined") ? "Adresse immer mit Hausnummer eingeben" : ""}

                                                                        />
                                                                    </AddressAutofill>
																	  {(typeof lon === "undefined" && typeof addressZipCode === "undefined")? (""):(
																		<div>
																			<TextField
	                                                                           label={"Postleitzahl"}
	                                                                           name={"postcode"}
	                                                                           InputLabelProps={{
                                                                                   shrink: true,
                                                                               }}
	                                                                           disabled={true}
	                                                                           value={(addressZipCode)? addressZipCode : ""}
	                                                                           autoComplete={"postal-code"}
	                                                                           type={"text"}
	                                                                        />

	                                                                        <TextField
	                                                                           label={"Ort"}
	                                                                           name={"city"}
	                                                                           InputLabelProps={{
                                                                                   shrink: true,
                                                                               }}
	                                                                           disabled={true}
	                                                                           value={(addressTown)? addressTown : ""}
	                                                                           autoComplete={"address-level2"}
	                                                                           type={"text"}
	                                                                        />


	                                                                        <TextField
	                                                                           label={"Längengrad"}
	                                                                           name={"latitude"}
	                                                                           InputLabelProps={{
	                                                                               shrink: true,
	                                                                           }}
	                                                                           disabled={true}
	                                                                           value={(lat)? lat : 0}
	                                                                           type={"number"}
	                                                                        />

	                                                                        <TextField
	                                                                           label={"Breitengrad"}
	                                                                           name={"longitude"}
	                                                                           InputLabelProps={{
		                                                                           shrink: true,
		                                                                       }}
	                                                                           disabled={true}
	                                                                           value={(lon)? lon : 0}
	                                                                           type={"number"}
	                                                                        />

																			<TextField
																				label={"Bundesland"}
																				name={"region"}
																				InputLabelProps={{
																					shrink: true,
																				}}
																				disabled={true}
																				value={(addressRegionCode)? addressRegionCode : ""}
																				autoComplete={"region-code"}
																				type={"text"}
																			/>
                                                                        </div>
																	  )}

                                                                  </form>
                                                               );
                                                           }
                                                       if(d?.type === "map") {
	                                                       return (
	                                                            <>
		                                                            <div className="map-container" ref={ref}  ></div>
			                                                        <div class="calculation-box">
	                                                                    <div id="calculated-area"></div>
                                                                    </div>
		                                                        </>
		                                                   );
                                                       }
                                                       if(d?.type === "number") {
                                                           if(d?.ShowIfFieldGiven) {
	                                                          if(typeof project?.[d?.ShowIfFieldGiven] !== "undefined" && project?.[d?.ShowIfFieldGiven] !== false)  {
																return (
																  <TextField
																   name={d?.id}
																   required={handleRequirements(flows?.flows[json],d.id)}
																   onChange={handleChangeNumber}
																   style={{width: d?.width, marginLeft: d?.margin}}
																   value={(project?.[d?.id] > 0) ? project?.[d?.id] : 0 }
																   InputProps={{
																     startAdornment: (
																       <InputAdornment position="start">
																           {d?.iconBefore}
																       </InputAdornment>
																     ),
																   endAdornment: (
																       <InputAdornment position="end">
																          {d?.iconAfter}
																       </InputAdornment>
																     ),
																   }}

																   {...d}
																   />
																)
                                                              }
                                                           }
                                                           else
	                                                       {
                                                              return (
                                                                  <TextField
                                                                   name={d?.id}
                                                                   required={handleRequirements(flows?.flows[json],d.id)}
                                                                   onChange={handleChangeNumber}
                                                                   style={{width: d?.width, marginLeft: d?.margin}}
                                                                   value={(project?.[d?.id]) ? project?.[d?.id] : "" }
                                                                   InputProps={{
                                                                     startAdornment: (
                                                                       <InputAdornment position="start">
                                                                           {d?.iconBefore}
                                                                       </InputAdornment>
                                                                     ),
                                                                   endAdornment: (
                                                                       <InputAdornment position="end">
                                                                          {d?.iconAfter}
                                                                       </InputAdornment>
                                                                     ),
                                                                   }}

                                                                   {...d}
                                                                   />

	                                                          );
	                                                       }
                                                      }
                                                       if(d?.type === "select") {

													        return (
	                                                           <FormControl style={{width: d?.width, marginLeft: d?.margin}}>
	                                                                <InputLabel id={d?.id}>{d?.label}</InputLabel>
	                                                                <Select
	                                                                    id={d?.id}
	                                                                    required={handleRequirements(flows?.flows[json],d.id)}
	                                                                    name={d?.id}

	                                                                    onChange={handleChangeSelect}
	                                                                    value={(project?.[d?.id]) ? project?.[d?.id] : d?.defaultValueOption }
	                                                                    {...d}
	                                                                >
																	{d?.option.map(function(a) {
                                                                        return(<MenuItem value={a}>{a}</MenuItem>)
                                                                    })}
	                                                                </Select>
	                                                            </FormControl>
	                                                        )
                                                      }
                                                       if(d?.type === "checkbox") {
                                                           if(d?.ShowIfFieldGiven) {
                                                               if(typeof project?.[d?.ShowIfFieldGiven] !== "undefined" && project?.[d?.ShowIfFieldGiven] !== false)  {
	                                                               return (
	                                                                   <div className={"switcher"}>
	                                                                      <Switch
	                                                                          name={d?.id}
	                                                                          onChange={handleCheckChange}
	                                                                          required={handleRequirements(flows?.flows[json],d.id)}
	                                                                          style={{width: d?.width, marginLeft: d?.margin}}
	                                                                          checked={ ( project?.[d?.id] === true)? true : false }
	                                                                          />
	                                                                          <span>{d?.label}</span>
	                                                                  </div>
	                                                               );
	                                                           }
                                                           }
                                                           else
                                                           {
                                                               return (
                                                                   <div className={"switcher"}>
                                                                      <Switch
                                                                          name={d?.id}
                                                                          onChange={handleCheckChange}
                                                                          required={handleRequirements(flows?.flows[json],d.id)}
                                                                          style={{width: d?.width, marginLeft: d?.margin}}
                                                                          checked={ ( project?.[d?.id] === true)? true : false }
                                                                          />
                                                                          <span>{d?.label}</span>
                                                                  </div>
                                                               );
                                                           }

                                                       }
                                                       if(d?.type === "calculation") {

                                                            if(d?.action === "divideFrom") {
                                                                return (
                                                                    <div  className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
                                                                            <div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                            if(d?.action === "minusFrom") {
                                                                return (
                                                                    <div  className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
                                                                            <div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                            else if(d?.action === "percentFrom") {
                                                                return (
                                                                    <div className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
                                                                            <div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                            else if(d?.action === "multiFrom") {
                                                                return (
                                                                    <div className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
                                                                            <div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                            else if(d?.action === "addFrom") {
                                                                return (
                                                                    <div className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
																			<div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                            else if(d?.action === "addPercentFrom") {
                                                                return (
                                                                    <div className={"calculatedInput"} style={{width: d?.width, marginLeft: d?.margin , marginBottom: d?.marginBottom}} title={"Dieses Feld berechnet sich selbst, und kann nicht Editiert werden."}>
                                                                         <span>
                                                                            {d?.label}
                                                                         </span>
                                                                         <div>
																			<div>{d?.prefix}&nbsp;&nbsp;{project?.[d?.id]}</div>
                                                                            <span>{d?.unit}</span>
                                                                         </div>
                                                                    </div>
                                                                );
                                                            }
                                                       }
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                      </SwiperSlide>
                                 )
                              })
                           }
						<div className={"swiper-button-next back"} style={{visibility: showButton ? 'visible' : 'hidden' }} type="submit" onClick={() => { saveProject("submit"); }}></div>
                        </Swiper>

                 </div>

            </div>
        );

}
