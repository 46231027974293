import React, {  useState , useContext} from 'react';
import * as turf from "@turf/turf";
import properties from "../../../configs/propertiesData.json";
import {DraftContext} from "../../../context/DraftContext";


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default function Page(){




    const {draft,draftId,project,user} = useContext(DraftContext);
    const [flowfactList, setFlowfactList] = useState(null);
    const [token, setToken] = useState(null);
    const [buttonLabel, setButtonLabel] = useState("Objektvarianten erstellen");

    const renderFlowfactList = (newflowfactList) => {
        return (
            <div class="card flowfactList">
                { newflowfactList?.entries?.map((entity) => (
                    <div>
                        <a target="_blank" href={"https://apps.flowfact.com/entities/entity-overview/entityId/"+entity?.id+"/schemaId/house_purchase/layoutId/house_purchase"}>
                            <div class="cardName">
                                <h5>{entity?.internaldescription?.values?.[0]} </h5>
                            </div>
                            <div class="flowfactCardDetails">
                                <div>
                                    <span>Stand: 16.3.2023, 16:32 Uhr</span>
                                </div>
                                <div>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <title>Zimmer</title>
                                        <path d="M19 14h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h3v-2H5v-2H3v3a1 1 0 0 0 1 1zM19 4a1 1 0 0 0-1-1h-3v2h2v2h2V4zM5 5h2V3H4a1 1 0 0 0-1 1v3h2V5zM3 9h2v4H3zm14 0h2v3h-2zM9 3h4v2H9zm0 14h3v2H9z">
                                        </path>
                                    </svg>
                                    <span>{entity?.rooms?.values?.[0]}</span>
                                </div>
                                <div>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <title>Grundstücksfläche</title>
                                        <path d="M685.904 383.184l275.264-273.572-.896 155.072c-.289 12.928 9.967 24.176 22.912 23.888l16.416-.016c12.944-.304 23.648-8 23.92-20.928l.672-231.008c0-.223-.88-.399-.88-.623l1.264-11.712c.128-6.496-1.391-12.288-5.584-16.431-4.143-4.176-9.951-6.72-16.4-6.544l-11.696.272c-.223 0-.399.08-.64.113L760.77.687c-12.928.288-23.632 10.976-23.92 23.92l-.032 16.417c1.967 15.248 13.952 24.16 26.88 23.872l151.248.4L640.657 337.92c-12.496 12.496-12.496 32.752 0 45.264 12.496 12.48 32.752 12.48 45.247 0zM339.088 640.812L63.825 913.532l.88-154.224c.304-12.944-9.969-24.192-22.897-23.904l-17.423.032c-12.96.32-23.649 8-23.921 20.944l-.672 231.008c0 .224.88.367.88.623l-1.264 11.68c-.144 6.496 1.376 12.32 5.584 16.433 4.128 4.192 9.952 6.72 16.384 6.56l11.712-.288c.223 0 .383-.096.64-.096l230.495 1.008c12.928-.32 23.617-11.009 23.92-23.936l.032-16.432c-1.967-15.216-13.952-24.16-26.88-23.872l-151.247-.4L384.32 686.076c12.496-12.497 12.496-32.752 0-45.248s-32.737-12.512-45.233-.016zm685.122 346.56l-.672-231.01c-.288-12.944-10.992-20.624-23.92-20.944l-16.416-.032c-12.944-.289-23.184 10.975-22.912 23.903l.896 155.072-275.28-273.552c-12.496-12.496-32.752-12.496-45.248 0s-12.496 32.752 0 45.248L914.93 958.649l-151.232.4c-12.928-.288-24.912 8.657-26.88 23.872l.032 16.432c.304 12.944 11.008 23.633 23.92 23.936l229.504-1.007c.24 0 .416.095.64.095l11.696.288c6.448.16 12.272-2.368 16.4-6.56 4.193-4.128 5.696-9.936 5.584-16.432l-1.263-11.68c0-.255.88-.399.88-.622zM110.049 65.321l151.264-.397c12.928.288 24.912-8.64 26.88-23.873l-.032-16.431C287.84 11.677 277.15.972 264.24.7l-230.512.992c-.256-.032-.416-.112-.64-.112l-11.712-.273C14.945 1.132 9.105 3.676 4.992 7.851.784 11.995-.735 17.787-.592 24.283L.672 35.995c0 .223-.88.384-.88.624l.672 231.008c.288 12.928 10.977 20.624 23.921 20.928l17.424.015c12.928.288 23.183-10.96 22.895-23.888l-.88-154.224 275.264 272.72c12.48 12.497 32.753 12.497 45.25 0s12.496-32.768 0-45.264z">
                                        </path>
                                    </svg>
                                    <span>{entity?.plotarea?.values?.[0]} m²</span>
                                </div>
                                <div>
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <title>Verkaufspreis</title>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z">
                                        </path>
                                    </svg>
                                    <span> {entity?.purchaseprice?.values?.[0]} €</span>
                                </div>
                            </div>
                        </a>
                        <div>
                            <div onClick={() => {deleteFlowfactEntity(token, entity?.id)}} class="delete" >
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    // token
    async function getToken(token) {
        var headers = new Headers();
        headers.append("token",token);

        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };


        const a = await fetch("https://api.production.cloudios.flowfact-prod.cloud/admin-token-service/public/adminUser/authenticate", requestOptions);

        setToken(await a.text());
    }


    // handel number field changes
    const  handleFlowfactProjectPublishing = () => {

        if(user !== null) {

            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("cognitoToken", token);

            var raw = JSON.stringify({
                "id": user?.attributes?.sub,
                "status": {
                    "values": [
                        "active"
                    ]
                },
                "firstName": {
                    "values": [
                        user?.attributes?.["given_name"]
                    ]
                },
                "lastName": {
                    "values": [
                        user?.attributes?.["family_name"]
                    ]
                },
                "emails": {
                    "values": [
                        user?.attributes?.["custom:email"]
                    ]
                },
                "company": {
                    "values": [
                        user?.attributes?.["custom:company"]
                    ]
                },
                "phones": {
                    "values": [
                        {
                            "number": user?.attributes?.["custom:phone"],
                            "type": "private",
                            "note": ""
                        },
                        {
                            "number": user?.attributes?.["custom:mobile"],
                            "type": "private",
                            "note": ""
                        }
                    ]
                }
            });

            var requestOptions = {
                method: 'PUT',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = fetch('https://api.production.cloudios.flowfact-prod.cloud/entity-service/schemas/contacts/entities/'+user?.attributes?.sub, requestOptions)
                .catch(error => console.log('error', error));
        }

        for (var a in draft?.json?.mapGeoJson?.features) {
            var point = draft?.json?.mapGeoJson?.features[a];
            var pointData = draft?.json?.mapGeoJson?.features[a]?.geometry?.coordinates;

            if (point?.geometry?.type === 'Point') {

                setButtonLabel("Übertragung läuft ...");

                for (var b in draft?.json?.mapGeoJson?.features) {
                    var polygon = draft?.json?.mapGeoJson?.features[b];
                    var polygonData = draft?.json?.mapGeoJson?.features[b]?.geometry?.coordinates;

                    if (polygon?.geometry?.type === 'Polygon'  && typeof pointData !== "undefined" && typeof polygonData !== "undefined") {

                        var a = turf.point(pointData);
                        var b = turf.polygon(polygonData);

                        if(turf.booleanPointInPolygon(a, b)) {
                            for (var i in draft?.json?.propertyData?.[point.id]["propertyType"]) {
                                for(var p in properties["properties"]) {
                                    if(draft?.json?.propertyData?.[point.id]["propertyType"][i] === properties["properties"][p]["name"]){
                                        var headers = new Headers();
                                        headers.append("Content-Type", "application/json");
                                        headers.append("cognitoToken", token);

                                        if(draft?.json?.incidentalBuildingCosts) {
                                            var incidentalBuildingCosts = draft?.json?.incidentalBuildingCosts;
                                        } else {
                                            var incidentalBuildingCosts = 0;
                                        }

                                        var price = 0;

                                        if(typeof draft?.json?.mapData?.[polygon.id]?.purchasePrice !== "undefined") {
                                            price =  price + Number(draft?.json?.mapData?.[polygon.id]?.purchasePrice);
                                        }

                                        if(typeof properties?.json?.[p]?.incidentalBuildingCosts !== "undefined") {
                                            price =  price + Number(properties?.json?.[p]?.incidentalBuildingCosts);
                                        }

                                        if(typeof properties["properties"][p]?.price !== "undefined" && typeof properties["properties"][p]?.units !== "undefined") {


                                            var housePrice = Number(properties["properties"][p]?.price) / Number(properties["properties"][p]?.units);


                                            if(typeof draft?.json?.regionalPremiumPercent !== "undefined" && typeof draft?.json?.regionalPremiumPercentMin !== "undefined" && typeof draft?.json?.regionalPremiumPercentMax !== "undefined") {

                                                var regionalPremium =  (housePrice * (Number(draft?.json?.regionalPremiumPercent) / 100))
                                                console.log("###############################");
                                                console.log("-------------------------");
                                                console.log("HousePrice: "+housePrice);
                                                console.log("regionalPremiumPercent: " +draft?.json?.regionalPremiumPercent);
                                                console.log("-------------------------");
                                                console.log("regionalPremium: "+regionalPremium);

                                                if(regionalPremium > draft?.json?.regionalPremiumPercentMax) {
                                                    regionalPremium =  Number(draft?.json?.regionalPremiumPercentMax);
                                                } else if(regionalPremium < draft?.json?.regionalPremiumPercentMin) {
                                                    regionalPremium =  Number(draft?.json?.regionalPremiumPercentMax);
                                                }


                                                console.log("-------------------------");
                                                console.log("regionalPremiumPercentMin: "+draft?.json?.regionalPremiumPercentMin);
                                                console.log("regionalPremiumPercentMax: "+draft?.json?.regionalPremiumPercentMax);
                                                console.log("-------------------------");
                                                console.log("NewRegionalPremium: "+ regionalPremium);
                                                console.log("NewHousePrice: "+ (housePrice+regionalPremium));
                                            }   console.log("###############################");



                                            price =  price + (housePrice+regionalPremium);
                                        }

                                        if(typeof draft?.json?.regionalPremium !== "undefined" ) {
                                            price =  price + Number(draft?.json?.regionalPremium);
                                        }


                                        if(typeof draft?.json?.otherPremium !== "undefined" ) {
                                            price =  price + Number(draft?.json?.otherPremium);
                                        }


                                        var raw = JSON.stringify({
                                            "flowdsl": {
                                                "target": "ENTITY",
                                                "fetch": [],
                                                "aggregations": [],
                                                "conditions": [
                                                    {
                                                        "type": "AND",
                                                        "conditions": [
                                                            {
                                                                "type": "HASFIELDWITHVALUE",
                                                                "value": draftId+"-"+polygon.id +"-"+ properties["properties"][p]?.id,
                                                                "operator": "EQUALS",
                                                                "field": "keywords"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            "entity": {
                                                "status": {
                                                    "values": [
                                                        "active"
                                                    ]
                                                },
                                                "addresses": {
                                                    "values": [
                                                        {
                                                            "zipcode": project?.json?.addressZipCode,
                                                            "country": "Deutschland",
                                                            "city": project?.json?.addressTown,
                                                            "street": project?.json?.addressStreet,
                                                            "type": ""
                                                        }
                                                    ]
                                                },
                                                "contact": {
                                                    "values": [
                                                        user?.attributes?.sub
                                                    ]
                                                },
                                                "livingarea": {
                                                    "values": [
                                                        Number(properties["properties"][p]?.size)
                                                    ]
                                                },
                                                "plotarea": {
                                                    "values": [
                                                        Number(draft?.json?.mapData?.[polygon.id]?.area)
                                                    ]
                                                },
                                                "rooms": {
                                                    "values": [
                                                        Number(properties["properties"][p]?.rooms)
                                                    ]
                                                },
                                                "purchaseprice": {
                                                    "values": [
                                                        price
                                                    ]
                                                },
                                                "textEstate": {
                                                    "values": [
                                                        properties["properties"][p]?.description
                                                    ]
                                                },
                                                "textEnvironment": {
                                                    "values": [
                                                        properties["properties"][p]?.equipmentDescription
                                                    ]
                                                },
                                                "textFree": {
                                                    "values": [
                                                        properties["properties"][p]?.freeDescription
                                                    ]
                                                },
                                                "textLocation": {
                                                    "values": [
                                                        draft?.json?.locationDescription
                                                    ]
                                                },
                                                "no_of_floors": {
                                                    "values": [
                                                        Number(properties["properties"][p]?.floors)
                                                    ]
                                                },
                                                "estatetype": {
                                                    "values": [
                                                        properties["properties"][p]?.type
                                                    ]
                                                },
                                                "keywords": {
                                                    "values": [
                                                        draftId+"-"+polygon.id +"-"+ properties["properties"][p]?.id
                                                    ]
                                                },
                                                "headline": {
                                                    "values": [
                                                        draft?.name+" - "+properties["properties"][p]?.name
                                                    ]
                                                },
                                                "internaldescription": {
                                                    "values": [
                                                        project?.name+" - "+draft?.json?.mapData?.[polygon.id]?.name+" - "+properties["properties"][p]?.name
                                                    ]
                                                },
                                                "completiondate": {
                                                    "values": [
                                                        2024
                                                    ]
                                                },
                                                "yearofconstruction": {
                                                    "values": [
                                                        2024.0
                                                    ]
                                                }
                                            }
                                        });

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: headers,
                                            body: raw,
                                            redirect: 'follow'
                                        };


                                        var url = "";
                                        if(window.location.hostname === "localhost") {
                                            url = "http://"+window.location.hostname+":"+window.location.port+"/"+process.env.PUBLIC_URL;
                                        } else {
                                            url = "https://"+window.location.hostname+"/"+process.env.PUBLIC_URL;
                                        }

                                        handleFlowfactUploadEstate(requestOptions,url,properties["properties"][p],token);
                                    }
                                }
                            }
                        }
                    }

                }

                sleep(4000).then(() => {

                    getFlowfactList(token);
                    setButtonLabel("Objektvarianten erstellen");
                });

            }
        }






    }    // handel number field changes

    const  handleFlowfactUploadEstate = async (requestOptions,url,properties,token) => {
        fetch('https://api.production.cloudios.flowfact-prod.cloud/entity-service/schemas/house_purchase/createifabsent', requestOptions)
            .then(result => result.json())
            .then(result => {

                if(properties?.image) {
                    setTimeout(500);
                    handleFlowfactUploadImages(result,url+properties?.image,properties,token,"flowfact_client" ,"main_image",0);
                    setTimeout(500);
                    handleFlowfactUploadImages(result,url+properties?.image,properties,token,"homepage" ,"main_image",0);
                    setTimeout(500);
                    handleFlowfactUploadImages(result,url+properties?.image,properties,token,"portal" ,"main_image",0);
                    setTimeout(500);
                    handleFlowfactUploadImages(result,url+properties?.image,properties,token,"iex" ,"main_image",0);

                    if(properties?.link?.homepage) {
                        setTimeout(50);
                        handleFlowfactUploadLinks(result, properties?.link?.homepage, "Webseite","links", token);
                    }

                    if(properties?.link?.video) {
                        setTimeout(50);
                        handleFlowfactUploadLinks(result, properties?.link?.video, "Video", "link_property_video", token);
                    }

                    if(properties?.imageGallery) {
                        for(var q = 0; q < properties?.imageGallery.length; q++) {
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.imageGallery?.[q]?.image,properties,token,"homepage" ,"pictures",q);
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.imageGallery?.[q]?.image,properties,token,"portal" ,"pictures",q);
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.imageGallery?.[q]?.image,properties,token,"iex" ,"pictures",q);
                        }
                    }

                    if(properties?.floorPlan) {
                        for(var q = 0; q < properties?.floorPlan.length; q++) {
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.floorPlan?.[q]?.image,properties,token,"homepage" ,"floorplans",q);
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.floorPlan?.[q]?.image,properties,token,"portal" ,"floorplans",q);
                            setTimeout(500);
                            handleFlowfactUploadImages(result,url+properties?.floorPlan?.[q]?.image,properties,token,"iex" ,"floorplans",q);
                        }
                    }
                }
            });
    };

    const handleFlowfactUploadImages = async (result,url,properties,token, albumName,categories,position) => {

        const imageFile = await fetch(url)
            .then((response) => response.blob())
            .then((pictures) =>  {
                if(typeof result?.entityIds?.[0] !== "undefined" && token) {
                    var myHeaders = new Headers();
                    myHeaders.append("cognitoToken", token);

                    var formdata = new FormData();
                    formdata.append("file", pictures, "Bild-"+Math.floor(Math.random(10000,99999)*10000)+".jpg");
                    formdata.append("title", "Bild-"+Math.floor(Math.random(10000,99999)*10000));

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch("https://api.production.cloudios.flowfact-prod.cloud/multimedia-service/items/schemas/house_purchase/entities/"+result?.entityIds?.[0], requestOptions)
                        .then(response => response.json())
                        .then(image => {

                            var myHeaders = new Headers();
                            myHeaders.append("cognitoToken", token);
                            myHeaders.append("Content-Type", "application/json");

                            var raw = JSON.stringify(
                                {
                                    "albumName": albumName,
                                    "categories": [
                                        categories
                                    ],
                                    "multimediaItemIds": [
                                        image?.multimediaItem?.id
                                    ],
                                    "sorting": position
                                });

                            var requestOptions2 = {
                                method: 'PUT',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };


                            if(result?.entityIds?.[0] !== null) {
                                fetch("https://api.production.cloudios.flowfact-prod.cloud/multimedia-service/assigned/schemas/house_purchase/entities/"+result?.entityIds?.[0]+"/items", requestOptions2)
                                    .catch(error => console.log('error', error));

                            }

                        })
                        .catch(error => console.log('error', error));
                }
            })
            .catch(error => console.log('error', error));


    };

    const handleFlowfactUploadLinks = async (result,  linkUrl, linkName, type,  token) => {

        if(token && linkName && linkUrl) {
            var myHeaders = new Headers();
            myHeaders.append("cognitoToken", token);
            myHeaders.append("Content-Type", "application/json");

            var json = JSON.stringify({
                "albumAssignments": [
                    {
                        "albumName": "homepage",
                        "categories": [
                            String(type)
                        ]
                    },
                    {
                        "albumName": "portal",
                        "categories": [
                            String(type)
                        ]
                    }
                ],
                "link": String(linkUrl),
                "title": String(linkName)
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: json,
                redirect: 'follow'
            };

            fetch("https://api.production.cloudios.flowfact-prod.cloud/multimedia-service/items/schemas/house_purchase/entities/"+result?.entityIds?.[0]+"/link", requestOptions)
                .then(data => data.json())
                .catch(error => console.log('error', error));
        }


    };

    async function deleteFlowfactEntity(token,entityId, flowfactList) {

        var headers = new Headers();
        headers.append("cognitoToken", token);
        headers.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: headers,
            redirect: 'follow'
        };


        const response = fetch('https://api.production.cloudios.flowfact-prod.cloud/entity-service/schemas/house_purchase/entities/'+entityId, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));

        getFlowfactList(token);
    }

    // user
    async function getFlowfactList(token) {

        if(typeof token !== "undefined") {

            var headers = new Headers();
            headers.append("cognitoToken", token);
            headers.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "target": "ENTITY",
                "fetch": [],
                "aggregations": [],
                "conditions": [
                    {
                        "type": "AND",
                        "conditions": [
                            {
                                "type": "HASFIELDWITHVALUE",
                                "value": draftId.toString(),
                                "operator": "LIKE",
                                "field": "keywords"
                            }
                        ]
                    }
                ]
            });

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = fetch('https://api.production.cloudios.flowfact-prod.cloud/search-service/schemas/house_purchase', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setFlowfactList(JSON.parse(result));
                })
                .catch(error => console.log('error', error));



        }
    }





    // init List
    if(token !== null  && flowfactList === null ) {
        getFlowfactList(token);
    }

    // init token
    if(typeof user?.["attributes"]?.["custom:apiFlowfactToken"] !== "undefined" && token == null  ) {
        getToken(user?.["attributes"]?.["custom:apiFlowfactToken"]);
    }

    return  (
        <div>
            {(typeof user?.["attributes"]?.["custom:apiFlowfactToken"] !== "undefined") ? (
                <div className={"wrapper-content box"}>
                    <div className="data-container">
                        <div>
                            <h3><span>FLOWFACT Sync</span><a type="submit" onClick={() => {handleFlowfactProjectPublishing() }}>{buttonLabel}</a></h3>
                            <p>Die folgenden Einstellungen werden für die Veröffentlichenung des Web-Exposes benötigt.</p>
                        </div>
                        <br/>
                        <br/>

                        { (flowfactList?.length !== null)?  renderFlowfactList(flowfactList) : "" }

                    </div>
                </div>
            ) :("") }
        </div>
    );

}

