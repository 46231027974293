import React, { useEffect, useRef, useState } from 'react';
import {useParams} from 'react-router';
import properties from '../../configs/propertiesData.json';
import navi from '../../component/navigation/Component-navigation';
import OnOfficeList from './components/OnOfficeAPI';
import FlowfactList from './components/FlowfactAPI';
import mapboxgl from 'mapbox-gl';
import {MapboxStyleDefinition,  MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import mapboxDraw from "@mapbox/mapbox-gl-draw";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import * as turf from '@turf/turf';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import "mapbox-gl-style-switcher/styles.css";
import { Auth } from 'aws-amplify';
import {SlClose} from "react-icons/sl";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Textarea from '@mui/joy/Textarea';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import 'react-tabs/style/react-tabs.css';
import {DraftContext} from "../../context/DraftContext";
import {FileContext} from "../../context/FileContext";
import OrderUploadBidHandler from "./components/files/orderUploadHandler";

export default function Page() {
    const [map, setMap] = useState(null);
    const [showSetting, setShowSetting] = useState(false);
    const [draft, setDraft] = useState(null);
    const [propertyList, setPropertyList] = useState(null);
    const [project, setProject] = useState(null);
    const [user, setUser] = useState(null);


	const ref = useRef(null);
    let {id,draftId} = useParams();


	const updateTextField = (e) => {

		var center = {};
		 	center.center = map.getCenter();
		 	center.zoom = map.getZoom();

		handelMapLoad(id, draft, project, null, center);
	}

	const mapKey = process.env.REACT_APP_MAPS_KEY;
          mapboxgl.accessToken = mapKey;

	// map styles
	const styles: MapboxStyleDefinition[] = [
        {
            title: "Satellit",
            uri:"mapbox://styles/mapbox/satellite-v9"
        },
        {
            title: "Straße",
            uri:"mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji"
        }
    ];

	// handel save
    const handelMapUpdate = (e) => {

		var data = [];

		for (var row of e.features) {

            var area          = turf.area(row);
            var center        = turf.center(row);
            //var rounded_area  = Math.round((area * 100)/ 100) ;

			console.log(draft?.json?.mapData?.[row.id]);

			data[row.id] = {

				center: center.geometry.coordinates,
				area: (draft?.json?.mapData?.[row.id]?.area)? draft?.json.mapData[row.id]?.area : 0,
				name: (draft?.json?.mapData?.[row.id]?.name)? draft?.json.mapData[row.id]?.name : "Neues Parzelle",
				usageType: (draft?.json?.mapData?.[row.id]?.usageType)? draft?.json.mapData[row.id]?.usageType : "",
				status: (draft?.json?.mapData?.[row.id]?.status)? draft?.json.mapData[row.id]?.status : "",
				purchasePrice : (draft?.json?.mapData?.[row.id]?.purchasePrice)? draft?.json.mapData[row.id]?.purchasePrice : 0,
			};

        }

		let final                       = draft;
		    final.lastUpdateDate        = draft.lastUpdateDate;
		    final.status                = draft.status;
		    final.json.mapGeoJson       = e;
		    final.json.mapData          = {...data};
		    final.json.propertyData     = {...draft?.json?.propertyData};

	    var raw = JSON.stringify(
	        final
	    );

		var headers = new Headers();
        	headers.append("Content-Type", "application/json");

	    var requestOptions = {
	      method: 'PUT',
	      headers: headers,
	      body: raw,
	      redirect: 'follow'
	    };

	    fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id+"/drafts/"+draft.id, requestOptions)
		setDraft(final);
    }

    const handleSaving = () => {

		var raw = JSON.stringify(
            draft
        );

		var headers = new Headers();
            headers.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'PUT',
          headers: headers,
          body: raw,
          redirect: 'follow'
        };

        const response = fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id+"/drafts/"+draft.id, requestOptions);
        setShowSetting(false);

		updateTextField("New Location Name");
    }

    const handleChange = (e) => {
        var newDraft = {...draft};
        var name = e.target.name;
        var ids   = name.split("#");
            name = ids[1];
        var id   = ids[0];


		newDraft.json.mapData[id][name] = e.target.value;
		setDraft(newDraft);
    }

    const handleMainChange = (e) => {
        var newDraft = {...draft};
        var name = e.target.name;
		newDraft[name] = e.target.value;
		setDraft(newDraft);
    }

    const handleCheckChange = (e) => {
        var newDraft = {...draft};
        var name = e.target.name;

		newDraft[name] = e.target.checked;

		setDraft(newDraft);
    }

    const handleSubChange = (e) => {

        var newDraft = {...draft};
        var name = e.target.name;
		newDraft.json[name] = e.target.value;
		setDraft(newDraft);
    }

    const handleMultiSelectChange = (e) => {
        var newDraft = {...draft};
        var name = e.target.name;
        var ids   = name.split("#");
            name = ids[1];
        var id   = ids[0];

		if(typeof newDraft?.json?.propertyData === "undefined")
		{
			newDraft.json.propertyData = [];
		}

		if(typeof newDraft?.json?.propertyData?.[id] === "undefined")
        {
            newDraft.json.propertyData[id] = [];
        }

		newDraft.json.propertyData[id] =  {propertyType: [...e.target.value]};

		setDraft(newDraft);
    }


	// user
	async function getUser() {
	    const user = await Auth.currentAuthenticatedUser();
	    setUser(user);
	}

	// load draft data
	async function getDraft() {

		var headers = new Headers();
			headers.append("Content-Type", "application/json");

		var raw = JSON.stringify(
		{
            schema: "projectsDrafts",
            condition: [
                {
                    type: "eq",
                    field: "id",
                    values: [
                        draftId
                    ]
                }
            ]
        });

		var requestOptions = {
		  method: 'POST',
		  headers: headers,
		  body: raw,
		  redirect: 'follow'
		};



		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
						 .catch(error => console.log('error', error));
	    const responseText = await response.json();


	    if (responseText.rows[0] && responseText.rows[0].published === null)
	    {
	        responseText.rows[0].published = false;
	    }

	    setDraft(responseText.rows[0]);
    }

	// load draft data
	async function getPropertiesList() {

		var list = [];

		for (var property of properties?.properties)
		{
				list.push(property.name);
		}

	    setPropertyList(list);
    }

	// load project data
    async function getProject() {

    		var headers = new Headers();
    			headers.append("Content-Type", "application/json");

    		var raw = JSON.stringify(
    		{
                schema: "projects",
                condition: [
                    {
                        type: "eq",
                        field: "id",
                        values: [
                            id
                        ]
                    }
                ]
            });

    		var requestOptions = {
    		  method: 'POST',
    		  headers: headers,
    		  body: raw,
    		  redirect: 'follow'
    		};

    		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
    						 .catch(error => console.log('error', error));

    	    const responseText = await response.json();
    	    setProject(responseText?.rows?.[0]);
    }

	// load map
	const handelMapLoad = (e, d, p, map, center) => {

		if (typeof ref !== "undefined" && ref?.current && map === null && e && d && p) {

			 let mapPlots = [];
			 let mapPlots2 = [];
			 let mapLabel = {
				 "type": "FeatureCollection",
				 "features": []
			 };
			 let mapMaster = [];
			 let g = p[0];
			 let x = d;

			 if(center !== null) {
				 var mapCenter = center?.center;
				 var mapZoom = center?.zoom;
			 }
			 else if(typeof  p?.json?.mapPolygon !== "undefined")
             {
                 var mapCenter = p?.json.mapPolygon?.features?.[0].properties?.center;
                 var mapZoom = 16;
             }
             else if(typeof p?.json?.mapCenter !== "undefined")
             {
                 var mapCenter = p?.json?.mapCenter;
                 var mapZoom = 16;
             }
             else
             {
                 var mapCenter = [10, 51.00];
                 var mapZoom = 6;
             }

		     const map = new mapboxgl.Map({
		         displayControlsDefault: false,
		         container: ref?.current,
		         style: "mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji",
				 center: mapCenter,
				 zoom: mapZoom,
                 maxZoom: 20,
		     });


		    var draw = new mapboxDraw({
	              displayControlsDefault: false,
	              controls: {
	                  polygon: true,
	                  point: true,
	                  trash: true
	              },
	              styles: [
	                  {
	                       'id': 'gl-draw-polygon-fill-inactive',
	                       'type': 'fill',
	                       'filter': ['all', ['==', 'active', 'false'],
	                           ['==', '$type', 'Polygon'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'paint': {
	                           'fill-color': '#3bb2d0',
	                           'fill-outline-color': '#3bb2d0',
	                           'fill-opacity': 0.1
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-fill-active',
	                       'type': 'fill',
	                       'filter': ['all', ['==', 'active', 'true'],
	                           ['==', '$type', 'Polygon']
	                       ],
	                       'paint': {
	                           'fill-color': '#fbb03b',
	                           'fill-outline-color': '#fbb03b',
	                           'fill-opacity': 0.1
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-midpoint',
	                       'type': 'circle',
	                       'filter': ['all', ['==', '$type', 'Point'],
	                           ['==', 'meta', 'midpoint']
	                       ],
	                       'paint': {
	                           'circle-radius': 3,
	                           'circle-color': '#fbb03b'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-stroke-inactive',
	                       'type': 'line',
	                       'filter': ['all', ['==', 'active', 'false'],
	                           ['==', '$type', 'Polygon'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#3bb2d0',
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-stroke-active',
	                       'type': 'line',
	                       'filter': ['all', ['==', 'active', 'true'],
	                           ['==', '$type', 'Polygon']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#fbb03b',
	                           'line-dasharray': [0.2, 2],
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-line-inactive',
	                       'type': 'line',
	                       'filter': ['all', ['==', 'active', 'false'],
	                           ['==', '$type', 'LineString'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#c4c4c4',
	                           'line-width': 16
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-line-active',
	                       'type': 'line',
	                       'filter': ['all', ['==', '$type', 'LineString'],
	                           ['==', 'active', 'true']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#fbb03b',
	                           'line-dasharray': [0.2, 2],
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
	                       'type': 'circle',
	                       'filter': ['all', ['==', 'meta', 'vertex'],
	                           ['==', '$type', 'Point'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'paint': {
	                           'circle-radius': 5,
	                           'circle-color': '#fff'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-and-line-vertex-inactive',
	                       'type': 'circle',
	                       'filter': ['all', ['==', 'meta', 'vertex'],
	                           ['==', '$type', 'Point'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'paint': {
	                           'circle-radius': 3,
	                           'circle-color': '#fbb03b'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-point-stroke-inactive',
	                       'type': 'circle',
	                       'filter': ['all', ['==', 'active', 'false'],
	                           ['==', '$type', 'Point'],
	                           ['==', 'meta', 'feature'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'paint': {
	                           'circle-radius': 5,
	                           'circle-opacity': 1,
	                           'circle-color': '#fff'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-inactive',
	                       'type': 'circle',
	                       'filter': ['all', ['==', 'active', 'false'],
	                           ['==', '$type', 'Point'],
	                           ['==', 'meta', 'feature'],
	                           ['!=', 'mode', 'static']
	                       ],
	                       'paint': {
	                           'circle-radius': 3,
	                           'circle-color': '#3bb2d0'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-stroke-active',
	                       'type': 'circle',
	                       'filter': ['all', ['==', '$type', 'Point'],
	                           ['==', 'active', 'true'],
	                           ['!=', 'meta', 'midpoint']
	                       ],
	                       'paint': {
	                           'circle-radius': 7,
	                           'circle-color': '#fff'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-active',
	                       'type': 'circle',
	                       'filter': ['all', ['==', '$type', 'Point'],
	                           ['!=', 'meta', 'midpoint'],
	                           ['==', 'active', 'true']
	                       ],
	                       'paint': {
	                           'circle-radius': 5,
	                           'circle-color': '#fbb03b'
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-fill-static',
	                       'type': 'fill',
	                       'filter': ['all', ['==', 'mode', 'static'],
	                           ['==', '$type', 'Polygon']
	                       ],
	                       'paint': {
	                           'fill-color': '#404040',
	                           'fill-outline-color': '#404040',
	                           'fill-opacity': 0.1
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-polygon-stroke-static',
	                       'type': 'line',
	                       'filter': ['all', ['==', 'mode', 'static'],
	                           ['==', '$type', 'Polygon']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#404040',
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-line-static',
	                       'type': 'line',
	                       'filter': ['all', ['==', 'mode', 'static'],
	                           ['==', '$type', 'LineString']
	                       ],
	                       'layout': {
	                           'line-cap': 'round',
	                           'line-join': 'round'
	                       },
	                       'paint': {
	                           'line-color': '#404040',
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-static',
	                       'type': 'circle',
	                       'filter': ['all', ['==', 'mode', 'static'],
	                           ['==', '$type', 'Point']
	                       ],
	                       'paint': {
	                           'circle-radius': 5,
	                           'circle-color': '#404040'
	                       }
	                   },

	                   {
	                       'id': 'gl-draw-polygon-color-picker',
	                       'type': 'fill',
	                       'filter': ['all', ['==', '$type', 'Polygon'],
	                           ['has', 'user_portColor']
	                       ],
	                       'paint': {
	                           'fill-color': ['get', 'user_portColor'],
	                           'fill-outline-color': ['get', 'user_portColor'],
	                           'fill-opacity': 0.5
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-line-color-picker',
	                       'type': 'line',
	                       'filter': ['all', ['==', '$type', 'LineString'],
	                           ['has', 'user_portColor']
	                       ],
	                       'paint': {
	                           'line-color': ['get', 'user_portColor'],
	                           'line-width': 2
	                       }
	                   },
	                   {
	                       'id': 'gl-draw-point-color-picker',
	                       'type': 'circle',
	                       'filter': ['all', ['==', '$type', 'Point'],
	                           ['has', 'user_portColor']
	                       ],
	                       'paint': {
	                           'circle-radius': 3,
	                           'circle-color': ['get', 'user_portColor']
	                       }
	                   },

	               ]

	        });
            map.addControl(draw);

			map.on('draw.create', function (e) {
                handelMapUpdate(draw.getAll(), "create");
                setShowSetting(null);
            });

            map.on('draw.update', function (e) {
                handelMapUpdate(draw.getAll(), "update");
            });

            map.on('draw.delete', function (e) {
                var a = draft;
                delete a.json.mapData[e.features[0].id];
                setDraft(a);

                handelMapUpdate(draw.getAll(), "delete");
                setShowSetting(null);
            });

            map.on('draw.selectionchange',function  (e) {
				/*if (e.features.length > 0) {
	                for (let i = 0; i < e.features.length; i++) {
	                    const element       = e.features[i];
	                    const area          = turf.area(element);
	                    const center        = turf.center(element);
						const rounded_area  = Math.round((area * 100)/ 100)

						//e.features[i].properties.center = center.geometry.coordinates;
	                   	//e.features[i].properties.area = rounded_area;
	                }
	            }*/

				setShowSetting(e);
            });

			map.addControl(new mapboxgl.NavigationControl());

			map.addControl(new MapboxStyleSwitcherControl(styles));


			var a = 0;
            map.on('load', function () {

                let lastZoom = map.getZoom();
				mapPlots2 = p?.json.mapPolygon;

                map.on('zoom', () => {
                  const currentZoom = map.getZoom();
                  if (currentZoom > lastZoom) {
                    // zoom in

                    if(map.getZoom() > 16 && a === 0)
                    {

                       map.addSource('wms-test-source', {
                           'type': 'raster',
                           'tiles': [
                               'https://www.wms.nrw.de/geobasis/wms_nw_inspire-flurstuecke_alkis?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
                           ],
                           'tileSize': 1126
                       });
                       map.addLayer(
                           {
                               'id': 'wms-test-layer',
                               'type': 'raster',
                               'source': 'wms-test-source',
                               'paint': {}
                           },
                           'building' // Place layer under labels, roads and buildings.
                       );

                       a = 1;

                    }

                  } else {}

                  lastZoom = currentZoom;
                });

				map.addSource('mapLayerD', {
					'type': 'geojson',
					'data': mapPlots2
				});

				// Add a black outline around the polygon.
				map.addLayer({
					'id': 'mapLayerD',
					'type': 'line',
					'source': 'mapLayerD',
					'layout': {},
					'paint': {
						'line-color': '#12ffa9',
						'line-width': 3
					}
				});
			});

            map.on('style.load', function () {

				mapPlots = x?.json.mapGeoJson;

				let lastZoom = map.getZoom();


				if(p?.json?.addressRegionCode === "NW") {
					map.addSource('wms-NW-source', {
						'type': 'raster',
						'tiles': [
							'https://www.wms.nrw.de/geobasis/wms_nw_inspire-flurstuecke_alkis?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-NW-layer',
							'type': 'raster',
							'source': 'wms-NW-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "TH") {
					map.addSource('wms-TH-source', {
						'type': 'raster',
						'tiles': [
							'https://www.geoproxy.geoportal-th.de/geoproxy/services/INSPIREcp?language=ger&REQUEST=GetMap&VERSION=1.1.1&SERVICE=WMS&STYLES=default&LAYERS=CP.CadastralParcel&srs=EPSG%3A3857&WIDTH=526&HEIGHT=526&FORMAT=image%2Fpng&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 200
					});
					map.addLayer(
						{
							'id': 'wms-TH-layer',
							'type': 'raster',
							'source': 'wms-TH-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "HE") {
					map.addSource('wms-HE-source', {
						'type': 'raster',
						'tiles': [
							'https://www.gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-maps.ows?language=ger&REQUEST=GetMap&VERSION=1.1.1&SERVICE=WMS&LAYERS=he_alk&srs=EPSG%3A3857&WIDTH=526&HEIGHT=526&FORMAT=image%2Fpng&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 200
					});

					map.addLayer(
						{
							'id': 'wms-HE-layer',
							'type': 'raster',
							'source': 'wms-HE-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "RP") {
					map.addSource('wms-RH-source', {
						'type': 'raster',
						'tiles': [
							'https://geo5.service24.rlp.de/wms/liegenschaften_rp.fcgi?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=WMS_RP_ALKIS_Liegenschaften&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-RH-layer',
							'type': 'raster',
							'source': 'wms-RH-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "MV") {
					map.addSource('wms-MV-source', {
						'type': 'raster',
						'tiles': [
							'https://www.geodaten-mv.de/dienste/inspire_cp_alkis_view?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-MV-layer',
							'type': 'raster',
							'source': 'wms-MV-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "BB") {
					map.addSource('wms-BB-source', {
						'type': 'raster',
						'tiles': [
							'https://inspire.brandenburg.de/services/cp_alkis_wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-BB-layer',
							'type': 'raster',
							'source': 'wms-BB-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "ST") {
					map.addSource('wms-ST-source', {
						'type': 'raster',
						'tiles': [
							'https://www.geodatenportal.sachsen-anhalt.de/wss/service/ST_LVermGeo_ALKIS_WMS_AdV_konform_App/guest?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=adv_alkis_flurstuecke,Farbe&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-ST-layer',
							'type': 'raster',
							'source': 'wms-ST-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "SN") {
					map.addSource('wms-SN-source', {
						'type': 'raster',
						'tiles': [
							'https://geodienste.sachsen.de/iwms_geosn_flurstuecke/guest?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CP.CadastralZoning%2CCP.CadastralParcel&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-SN-layer',
							'type': 'raster',
							'source': 'wms-SN-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}

				if(p?.json?.addressRegionCode === "NI") {
					map.addSource('wms-NI-source', {
						'type': 'raster',
						'tiles': [
							'https://opendata.lgln.niedersachsen.de/doorman/noauth/alkis_wms?VERSION=1.3.0&REQUEST=GetMap&SERVICE=WMS&FORMAT=image%2Fpng&LAYERS=ALKIS&CRS=EPSG%3A3857&STYLES=&WIDTH=1126&HEIGHT=1126&BBOX={bbox-epsg-3857}'
						],
						'tileSize': 1126
					});
					map.addLayer(
						{
							'id': 'wms-NI-layer',
							'type': 'raster',
							'source': 'wms-NI-source',
							'paint': {}
						},
						'building' // Place layer under labels, roads and buildings.
					);
				}



				for (var a of x?.json?.mapGeoJson?.["features"]) {

					if(a?.geometry?.type === "Polygon") {
						mapLabel?.features.push({
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": x?.json?.mapData?.[a?.["id"]]?.center
							},
							"properties": {
								"name": x?.json?.mapData?.[a?.["id"]]?.name
							}
						});
					}
				}

				if( typeof mapPlots !== "undefined" && mapPlots !== null ) {
				     draw.add(mapPlots);
				}

                 // Add a data source containing GeoJSON data.
				 map.addSource('mapLayer1', {
				    'type': 'geojson',
				    'data': { type:"FeatureCollection",features:[ mapMaster ] }
				 });

				 map.addSource('mapLabel', {
                     'type': 'geojson',
                     'data': mapLabel
                  });

				 // Add a new layer to visualize the polygon.
				 map.addLayer({
				     'id': 'mapLayer1',
				     'type': 'fill',
				     'source': 'mapLayer1',
				     'layout': {},
				     'paint': {
				     'fill-color': '#132541',
				     'fill-opacity': 0.5
				     }
				 });

				// Add a new layer to visualize the polygon.
				map.addLayer({
				   'id': 'mapLayer',
				   'type': 'fill',
				   'source': 'mapLayerD', // reference the data source
				   'layout': {},
				   'paint': {
				   'fill-color': '#12ffa9', // blue color fill
				   'fill-opacity': 0.1
				   }
				});

				map.addLayer({
					'id': 'mapLabel',
					'type': 'symbol',
					'source': "mapLabel", // reference the data source
					'paint': {
						"text-color":  "#000000",
						'text-halo-color': '#fff',
						'text-halo-width': 2,
						'icon-color':'#000000',
						"icon-halo-color": "#ff0000",
						"icon-halo-width": 2,
					},

					'layout': {
						'text-field': ['get','name'],
						'text-variable-anchor': ['top'],
						'text-radial-offset': 1.5,
						'text-justify': 'auto',
						'text-size': 12,

					}
				});
            });


		    setMap(map);

		}
		return true;

    }

	// init dataload
    useEffect(() => {
        getUser();
        if(id)  {
			getDraft();
			getProject();
			getPropertiesList();
        }
    }, [id]);

	// init map
	handelMapLoad(id, draft, project, map, null);



		// render
        return(

                <div id={"page-project-draft-details"}>
                    { navi("Projekt \""+draft?.name+"\" planen", "/project/"+id) }

                    <div  className={"wrapper-content"}>
                        <div  id={"page-drafts-map"}>

                                 <div className={"wrapper-content box"}>
                                     <div>
                                         <div className="map-container" ref={ref}  ></div>
                                     </div>
                                     { (showSetting !== false && showSetting?.features.length > 0 ) ?
                                        (
                                            <div className="polygonSettings" data-show={( showSetting === false ) ?  false : true }>
                                                 <div className="polygonSettingsContent" >
                                                     <button onClick={() => {setShowSetting(false);}}><SlClose/></button>
                                                     { (showSetting?.features[0].geometry.type === "Polygon" ) ?
                                                         (
                                                         <div>
                                                             <h3>Parzelle Beschreiben</h3>
                                                             <p>Sie haben nun die Möglichkeit die gewählte Parzelle nähr zu beschreiben.</p>
                                                            <div className="polygonSettingsContentPolygon" >
                                                                <TextField
                                                                   name={showSetting?.features[0].id+"#name"}
                                                                   label="Parzellen-Bezeichnung"
                                                                   type = "text"
                                                                   onChange={handleChange}
                                                                   value={(draft?.json.mapData[showSetting?.features[0].id]?.name) ? draft?.json.mapData[showSetting?.features[0].id].name : ""}
                                                                />

                                                                <TextField
                                                                   name={showSetting?.features[0].id+"#area"}
                                                                   label="Fläche"
                                                                   type = "number"
                                                                   onChange={handleChange}
                                                                   value={(draft?.json.mapData[showSetting?.features[0].id]?.area) ? draft?.json.mapData[showSetting?.features[0].id].area : 0}
                                                                   InputProps={{
                                                                        endAdornment: (
                                                                       <InputAdornment position="end">
                                                                          m²
                                                                       </InputAdornment>
                                                                     ),
                                                                   }}
                                                                />
                                                                <TextField
                                                                   name={showSetting?.features[0].id+"#purchasePrice"}
                                                                   label={(draft?.json.mapData[showSetting?.features[0].id]?.usageType === "forRent") ? "Kaltmiete" : "Kaufpreis der Parzelle"}
                                                                   type = "number"
                                                                   onChange={handleChange}
                                                                   value={(draft?.json.mapData[showSetting?.features[0].id]?.purchasePrice ) ? draft?.json.mapData[showSetting?.features[0].id].purchasePrice : ""}
                                                                   InputProps={{
                                                                        endAdornment: (
                                                                       <InputAdornment position="end">
                                                                          €
                                                                       </InputAdornment>
                                                                     ),
                                                                   }}
                                                                />


                                                                <FormControl>
                                                                    <InputLabel id="usageType">Vermarktungsart</InputLabel>
                                                                    <Select
                                                                        id={"usageType"}
                                                                        name={showSetting?.features[0].id+"#usageType"}
                                                                        label="Vermarktungsart"
                                                                        onChange={handleChange}
                                                                        value={(draft?.json.mapData[showSetting?.features[0].id]?.usageType ) ? draft?.json.mapData[showSetting?.features[0].id].usageType :  ""}
                                                                    >
                                                                        <MenuItem value={"forPurchase"}>Zum Kauf</MenuItem>
                                                                        <MenuItem value={"forRent"}>Zur Miete</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                <FormControl>
                                                                    <InputLabel id="status">Status</InputLabel>
                                                                    <Select
                                                                        id={"status"}
                                                                        name={showSetting?.features[0].id+"#status"}
                                                                        label="Status"
                                                                        value={(draft?.json.mapData[showSetting?.features[0].id]?.status ) ? draft?.json.mapData[showSetting?.features[0].id].status : ""}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={"available"}>Verfügbar</MenuItem>
                                                                        <MenuItem value={"gone"}>Vermittelt</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                                <a onClick={() => {handleSaving()}} > Speichern & schließen </a>
                                                            </div>
                                                        </div>
                                                        ) : (
                                                        <div>
                                                            <h3>Bebauung wählen</h3>
                                                             <p>Sie haben nun die möglichen Haustype zu wählen welche auf der Parzelle gebaut werden können.</p>
                                                             <div className="polygonSettingsContentPoint" >
                                                                 <FormControl >
                                                                     <InputLabel id="demo-multiple-name-label">Mögliche Bebauung</InputLabel>
                                                                     <Select
                                                                       id={"propertyType"}
                                                                       name={showSetting?.features[0].id+"#propertyType"}
                                                                       multiple
                                                                       value={(draft?.json?.propertyData?.[showSetting?.features[0].id]?.propertyType ) ? draft?.json.propertyData[showSetting?.features[0].id].propertyType : []}
                                                                       onChange={handleMultiSelectChange}
                                                                       input={<OutlinedInput label="Bebauung" />}
                                                                     >
                                                                       {propertyList.map((name) => (
                                                                         <MenuItem
                                                                           key={name}
                                                                           value={name}
                                                                         >
                                                                           {name}
                                                                         </MenuItem>
                                                                       ))}
                                                                     </Select>
                                                                   </FormControl>
                                                                   <a onClick={() => {handleSaving()}} > Speichern & schließen </a>
                                                             </div>
                                                        </div>
                                                        )}
                                                 </div>
                                             </div>
                                        ) : ("")
                                    }
                                 </div>


                                 {(typeof draft?.json?.mapGeoJson?.features !== "undefined" && draft?.json?.mapGeoJson?.features.length > 0) ? (
                                 <div>
                                     <div className={"wrapper-content box"}>
                                         <div className="data-container">
                                             <div>
                                                 <h3><span>Homepage Veröffentlichung</span></h3>
                                                 <p>Die folgenden Einstellungen werden für die Veröffentlichenung des Web-Exposes benötigt.</p>
                                                 <br/><br/>
                                                 <TextField
                                                     name={"name"}
                                                     label="Projekt-Name"
                                                     type = "text"
                                                     onChange={handleMainChange}
                                                     value={(draft?.name ) ? draft?.name : ""}

                                                 />
                                                 <div className={"textBlock"}>
                                                     <div>
                                                         <span>Projektbeschreibung</span>
                                                         <Textarea minRows={4}
                                                                   name={"conceptDescription"}
                                                                   label="Konzeptbeschreibung"
                                                                   type = "text"
                                                                   onChange={handleSubChange}
                                                                   value={(draft?.json?.conceptDescription ) ? draft?.json?.conceptDescription : ""}
                                                         />
                                                     </div>
                                                     <div>
                                                         <span>Lagebeschreibung</span>
                                                         <Textarea minRows={4}
                                                                   name={"locationDescription"}
                                                                   label="Lagebeschreibung"
                                                                   type = "text"
                                                                   onChange={handleSubChange}
                                                                   value={(draft?.json?.locationDescription ) ? draft?.json?.locationDescription : ""}
                                                         />
                                                     </div>
                                                 </div>
                                                 <br/>

                                                 <TextField
                                                     className={"incidentalBuildingCosts"}
                                                     name={"incidentalBuildingCosts"}
                                                     label="Baunebenkosten"
                                                     type = "number"
                                                     onChange={handleSubChange}
                                                     value={(draft?.json?.incidentalBuildingCosts ) ? draft?.json?.incidentalBuildingCosts :  ""}
                                                     InputProps={{
                                                         endAdornment: (
                                                             <InputAdornment position="end">
                                                                 €
                                                             </InputAdornment>
                                                         ),
                                                     }}
                                                 />
                                                 <br/>
                                                 <br/>
                                                 <TextField
                                                     className={"regionalPremiumPercent"}
                                                     name={"regionalPremiumPercent"}
                                                     label="Regionalzuschlag in %"
                                                     type = "number"
                                                     onChange={handleSubChange}
                                                     value={(draft?.json?.regionalPremiumPercent ) ? draft?.json?.regionalPremiumPercent :  ""}
                                                     InputProps={{
                                                         endAdornment: (
                                                             <InputAdornment position="end">
																 %
                                                             </InputAdornment>
                                                         ),
                                                     }}
                                                 />
                                                 <br/>
                                                 <br/>
												 <TextField
                                                     className={"regionalPremiumPercentMin"}
                                                     name={"regionalPremiumPercentMin"}
                                                     label="Regionalzuschlag (Min)"
                                                     type = "number"
                                                     onChange={handleSubChange}
                                                     value={(draft?.json?.regionalPremiumPercentMin ) ? draft?.json?.regionalPremiumPercentMin :  ""}
                                                     InputProps={{
                                                         endAdornment: (
                                                             <InputAdornment position="end">
																 €
                                                             </InputAdornment>
                                                         ),
                                                     }}
                                                 />
												 <br/>
                                                 <br/>
												 <TextField
                                                     className={"regionalPremiumPercentMax"}
                                                     name={"regionalPremiumPercentMax"}
                                                     label="Regionalzuschlag (Max)"
                                                     type = "number"
                                                     onChange={handleSubChange}
                                                     value={(draft?.json?.regionalPremiumPercentMax ) ? draft?.json?.regionalPremiumPercentMax :  ""}
                                                     InputProps={{
                                                         endAdornment: (
                                                             <InputAdornment position="end">
																 €
                                                             </InputAdornment>
                                                         ),
                                                     }}
                                                 />
												 <br/>
												 <br/>
												 <TextField
                                                     className={"regionalPremiumPercentMax"}
                                                     name={"otherPremium"}
                                                     label="Sontiger Zuschlag"
                                                     type = "number"
                                                     onChange={handleSubChange}
                                                     value={(draft?.json?.otherPremium ) ? draft?.json?.otherPremium :  ""}
                                                     InputProps={{
                                                         endAdornment: (
                                                             <InputAdornment position="end">
																 €
                                                             </InputAdornment>
                                                         ),
                                                     }}
                                                 />
                                                 <br/>
                                                 <br/>
                                                 <h3>
                                                     <FormControlLabel
                                                         value="end"
                                                         name={"published"}
                                                         checked={draft?.published}
                                                         control={<Switch color="primary" />}
                                                         label="Veröffentlichen"
                                                         onChange={handleCheckChange}
                                                         labelPlacement="end"
                                                     />
                                                     <a target="_blank" style={{border:0, textDecoration:"underline"}} type="submit" href={"https://main.d1at6m68ssvfy6.amplifyapp.com/"+user?.attributes?.["custom:companyID"]+"?preview="+draftId}>Vorschau</a>
													 <a type="submit" onClick={() => { handleSaving(); }}>Speichern</a>
												 </h3>


                                             </div>
                                         </div>
                                     </div>

									 <DraftContext.Provider value={{draft,draftId,project, user }}>
										 <FlowfactList />

										 <OnOfficeList />
									 </DraftContext.Provider>

                                     <div className={"wrapper-content box"}>
                                         <div className="data-container">
                                             <div>
                                                 <h3><span>Dokumente</span> <a type="submit" href={"/project/"+id+"/drafts/"+draftId+"/documents/"} >Zu den Formularen</a></h3>
                                                 <p>Erstellen Sie Vorgefertigte Unterlagen auf Basis des Ihres Bebauungskonzueptes.</p>
                                             </div>
									 	</div>
										 <div className={"myDocuments"}>
											 <FileContext.Provider className={"wrapper"} value={{id,draftId}}>
												 <OrderUploadBidHandler data={user}/>
											 </FileContext.Provider>
										 </div>
									 </div>


									 <div className={"wrapper-content box"}>
										 <div className="data-container">
                                             <div>
                                                 <h3><span>Löschen</span> <a className={"delete"} type="submit" href={"/delete/?projectId="+id+"&draftId="+draftId+"&type=draft&name="+draft?.name}>Löschen</a></h3>
                                                 <p>Mit der Löschung werden sämtliche Informationen zu diesem Projekt entfernt. Zugängen Sowie das Web-Exposé sind anschließend nicht mehr aufrufbar.</p>

                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 ):("")}

                        </div>
                    </div>
                </div>

        );

}
