import React, {useEffect, useState} from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {Auth} from "aws-amplify";
import { CiFolderOn, CiFileOn, CiExport   } from "react-icons/ci";

export default function Overview(){

	let {id,draftId} = useParams();
	const [user,setUser] = useState(null);

	// add company ID
	async function getUser() {
		const user = await Auth.currentAuthenticatedUser();
		setUser(user);
	}


	useEffect(() => {
		getUser();
	},[]);



	return(
        <div>
	        { navi("Dokumente", "/project/"+id+"/drafts/"+draftId) }
	         <div id={"page-project-documents"} className={"wrapper-content"}>
	             <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                 </p>
	             <div className={"documentOverview"}>

	                 <div className={"documentElement"}>
	                    <a href={"/project/"+id+"/drafts/"+draftId+"/documents/category"}>
	                         <div className={"previewImage"}>
								 <CiFolderOn className={"icon"} />
	                         </div>
	                         <div className={"name"}>
	                            Öffentliche Mittel
	                         </div>
	                     </a>
		             </div>



					 <div className={"documentElement"}>
						 <a href={`${process.env.PUBLIC_URL}/formes/Planungsauftrag_H&F_TC_V011.pdf`}>
							 <div className={"previewImage"}>
								 <CiFileOn  className={"icon"} />
							 </div>
							 <div className={"name"}>
								 Für Eigentümer
							 </div>
						 </a>
					 </div>

				 </div>
				 <br/>
				 <br/>
				 <div className={"documentOverview"}>
					 <div className={"documentElement"}>
						 <a  href={"/project/"+id+"/drafts/"+draftId+"/documents/finacing"}>
							 <div className={"previewImage"}>
								 <img className={"logo"} src={`${process.env.PUBLIC_URL}/formes/GLS_Logo.png`} />
							 </div>
							 <div className={"name"}>
								 Privatkunden-Baufinanzierung
							 </div>
						 </a>
					 </div>

					 {(user?.attributes?.["custom:serviceMember"] === "1") ? (
						 <div className={"documentElement"}>
							 <a href={"/project/"+id+"/drafts/"+draftId+"/documents/service"}>
								 <div>
									 <div className={"previewImage"}>
										 <img src={`${process.env.PUBLIC_URL}/formes/hflogo.png`} />
									 </div>
									 <div className={"name"}>
										 Servicegesellschaft Genossenschaften
									 </div>
								 </div>
							 </a>
						 </div>
					 ):("")}


					 <div className={"documentElement"}>
						 <a href={"/project/"+id+"/drafts/"+draftId+"/documents/spacedatists"}>
							 <div>
								 <div className={"previewImage"}>
									 <img src={`${process.env.PUBLIC_URL}/formes/logo.png`} />
								 </div>
								 <div className={"name"}>
									 XPlanung
								 </div>
							 </div>
						 </a>
					 </div>
				 </div>
			 </div>
		 </div>
    );
}



/*
* <div className={"documentElement"}>
                         <a target={"blank"} href={"/project/"+id+"/drafts/"+draftId+"/documents/category/developmentPlan"}>
                            <div className={"previewImage"}>
								<CiFileOn  className={"icon"} />
                            </div>
                            <div className={"name"}>
                               Bauantrag(NRW)
                            </div>
                         </a>
                     </div>
*
* */